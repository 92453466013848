import { IPageProps, IPropPageSeo } from '@ucheba/utils/helpers/components/types'
import { EModColorBgText } from '@ucheba/utils/helpers/styles/mod.types'
import { EModButtonColor } from '@ucheba/ui/components/Button/types'
import { IItemOfTags } from '@ucheba/store/tags/stats/types'
import { ILocation } from '@ucheba/store/location/types'
import {
  IPropButtonText,
  IPropDescription,
  IPropHref,
  IPropImgUrl,
  IPropList,
  IPropName,
  IPropSubtitle,
  IPropTitle,
  IPropValue,
} from '@ucheba/utils/helpers/components/prop.types'
import { Dispatch, SetStateAction } from 'react'
import { IDialog } from '@ucheba/ui/components/Dialog/types'

export interface IIndexPageProps<T = never> extends IPageProps<IPageDataIndex, T> {}

export interface IUseIndexPageSpecialtiesCore {
  (): {
    onClick: (_, slug: string) => void
    activeIndex: number
    activeCategory: string
    getSpecialitiesHref: (string: number) => string
    items: IItemOfTags[]
  }
}

export interface IUseBannerContent {
  ({
    isSubscribed,
    isNotSubscribed,
  }: {
    isSubscribed: IIndexPageProps['content']['banners']['isSubscribed']
    isNotSubscribed: IIndexPageProps['content']['banners']['isNotSubscribed']
  }): {
    // Как при такой записи можно экстендить интерфейс? типа extends IPropImgUrl
    boxColor: keyof typeof EModColorBgText
    buttonColor: keyof typeof EModButtonColor
    imgUrl: string
    content:
      | IIndexPageProps['content']['banners']['isSubscribed']
      | IIndexPageProps['content']['banners']['isNotSubscribed']
  }
}

export enum EInstitutionTypeValue {
  vuz = 'vuz',
  college = 'college',
}

export type TInstitutionTypeValue = keyof typeof EInstitutionTypeValue

export interface IUseInstitutionsRankingsCore {
  (): {
    openDropdownMenu: (id: number) => () => void
    isOpenDropdownMenu: (id: number) => boolean
    /** Не смог придумать, как добавить сюда тип, если я расширяю обычный
     * IInstitutionsRankingsStatsItem['children'] из массива в объект с
     * visibleSubRatings и dropdownSubRatings
     * */
    visibleRankingsList: any[]
    getRankingHref: (ratingItemId: number) => string
    getSubRankingHref: (subRatingItemId: number, ratingItemId: number) => string
  }
}

export interface IUseIndexPageCore {
  (): {
    locations: ILocation[]
    selectedLocations: ILocation[]
    institutionType: IInstitutionType
    setInstitutionType: Dispatch<SetStateAction<IInstitutionType>>
  }
}

interface IPropInstitutionCount {
  institutionCount: string
}

export interface IPageDataIndex extends IPropPageSeo {
  hero: IHero
  institutionLogotypes: IInstitutionLogotypes
  services: IServices
  specialties: ISpecialties
  ratings: IRatings
  tips: ITips
  banners: IBanners
  calendar: ICalendar
  chooseInstitutionTypeDialogContent: IChooseInstitutionTypeDialogContent
  chooseLocationDialogContent: IChooseLocationDialogContent
  footer: IFooter
}

interface IChooseInstitutionTypeDialogContent extends IPropTitle {
  closeDialog: IDialog['closeDialog']
  setInstitutionType: Dispatch<SetStateAction<IInstitutionType>>
}

interface IChooseLocationDialogContent
  extends IPropTitle,
    Required<IPropDescription>,
    IPropButtonText {
  closeDialog: IDialog['closeDialog']
  defaultLocations: ILocation[]
}

interface IHero extends Required<IPropHref>, IPropTitle {
  chooseLocationDialog?: IDialog
  chooseInstitutionTypeDialog?: IDialog
  isOpenChooseLocation?: boolean
  toggleOpenChooseLocation?: Dispatch<SetStateAction<boolean>>
  isOpenChooseInstitutionType?: boolean
  toggleOpenChooseInstitutionType?: Dispatch<SetStateAction<boolean>>
  institutions: IHeroItem
  students: IHeroItem
  programs: IHeroItem
  institutionType: IInstitutionType
  buttonText: {
    institution: string
    college: string
  }
}

export interface IInstitutionType extends IPropButtonText, IPropValue {
  visibleText: string
}

interface IHeroItem {
  text: string
  count: string
}

interface IInstitutionLogotypes extends IPropList<string> {}

interface IServices {
  ege: IServicesItem
  profession: IServicesItem
}

interface IServicesItem
  extends IPropTitle,
    Required<IPropDescription>,
    IPropButtonText,
    Required<IPropHref> {}

interface ISpecialties extends IPropTitle {
  institutionType: IInstitutionType
  buttons: ISpecialtiesButtons[]
  promoBlock: IPromoBlock
}

interface IPromoBlock
  extends IPropTitle,
    Required<IPropDescription>,
    IPropButtonText,
    Required<IPropHref> {}

interface ISpecialtiesButtons {
  name: string
  slug: string
}

export interface ISpecialtiesItem extends IPropName, ICategorySpecialties, IPropImgUrl {
  specialties: {
    count: number
    text: string
  }
  institutions: {
    count: number
    text: string
  }
}

export enum ECategorySpecialties {
  technology = 'technology',
  humanities = 'humanities',
  human = 'human',
  management = 'management',
  natural = 'natural',
}

export interface ICategorySpecialties {
  category: keyof typeof ECategorySpecialties
}

interface IRatings extends IPropTitle, IPropList<IRatingsItem> {}

interface IRatingsItem
  extends IPropImgUrl,
    IPropName,
    IPropInstitutionCount,
    Required<IPropHref> {
  year: number
  programs?: IRatingsItemProgram[]
}

interface IRatingsItemProgram extends Required<IPropHref>, IPropInstitutionCount {
  text: string
}

interface ITips
  extends IPropTitle,
    IPropButtonText,
    IPropList<ITipsItem>,
    Required<IPropHref> {}

interface ITipsItem extends IPropTitle, Required<IPropHref>, IPropDescription {}

interface IBanners {
  isSubscribed: IBannerItem
  isNotSubscribed: IBannerItem
}

interface IBannerItem
  extends IPropTitle,
    Required<IPropDescription>,
    IPropButtonText,
    Required<IPropHref> {}

interface ICalendar
  extends IPropTitle,
    IPropButtonText,
    IPropHref,
    IPropList<ICalendarItem> {}

interface ICalendarItem extends IPropImgUrl, IPropTitle, IPropName {
  date?: string
  institutionUrl: IPropImgUrl['imgUrl']
  eventUrl: IPropImgUrl['imgUrl']
  buttons?: {
    join?: ICalendarItemButtons
    details?: ICalendarItemButtons
  }
}

interface ICalendarItemButtons extends IPropButtonText, Required<IPropHref> {}

interface IFooter
  extends IPropTitle,
    IPropSubtitle,
    IPropButtonText,
    Required<IPropHref> {
  twoMillionOfStudents: IFooterItem
  CRMSystem: IFooterItem
}

interface IFooterItem extends IPropTitle, Required<IPropDescription> {}
