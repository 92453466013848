import { EProgramTypes } from '@ucheba/utils/types/program'
import data from './data.json'
import { ISectionsNavigationItem } from './types'

export const specialitiesPath = '/for-abiturients/speciality'

export const specialitiesSearchPath = `${specialitiesPath}/search`

export const sectionsNavigationItems: ISectionsNavigationItem[] = [
  {
    name: data.sectionsNavigation.higherName,
    titleForHeader: data.sectionsNavigation.higherNameHeader,
    slug: String(EProgramTypes.higher),
  },
  {
    name: data.sectionsNavigation.vocationalName,
    titleForHeader: data.sectionsNavigation.vocationalNameHeader,
    slug: String(EProgramTypes.vocational),
  },
]
