import { NextRouter, useRouter as useNextRouter } from 'next/router'
import { useMemo } from 'react'
import { parseUrl } from '../helpers/router'

/* Дополненный хук роутера Next.js */
export const useRouter = (): NextRouter & { params: NextRouter['query'] } => {
  const router = useNextRouter()
  const { url: pathname, query = {} } = useMemo(
    () => parseUrl(router.asPath),
    [router.asPath]
  )
  const params = useMemo(() => {
    return Object.keys(router.query).reduce(
      (acc, routerQueryKey) => {
        if (!query[routerQueryKey]) {
          acc[routerQueryKey] = router.query[routerQueryKey]
        }

        return acc
      },
      {} as NextRouter['query']
    )
  }, [query, router.query])

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return useMemo(
    () => ({ ...router, pathname, query, params }),
    [params, pathname, query, router]
  )
}
