import styled from 'styled-components'
import { sv } from '@ucheba/utils/helpers/styles/variables'

const Block = styled.div``

const StyledButton = styled.div`
  padding: 0;
  height: auto;
  line-height: inherit;
`

const DropdownItem = styled.div`
  && {
    color: ${sv.color.blue};
  }
`

export default { Block, StyledButton, DropdownItem }
