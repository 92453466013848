/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { IUserActivityState } from './types'
import { sliceName } from './constants'
import thunks from './thunks'
import { extraReducersResponse, responseInitialState } from '../../../utils/response'

export default createSlice({
  name: sliceName,

  initialState: {
    ...responseInitialState,
  },

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<IUserActivityState[typeof sliceName], null>({
      builder,
      thunk: thunks.add,
      fulfilled: () => null,
    })
  },
})
