import styled from 'styled-components'
import { sv } from '@ucheba/utils/helpers/styles/variables'

const Block = styled.div``

const Logotype = styled.img`
  max-height: 64px;
`

const LogotypesList = styled.div`
  display: flex;

  & li {
    margin-right: ${sv.spacing.large};
  }
`

export default { Block, Logotype, LogotypesList }
