import React, { FC, memo } from 'react'
import { IParamProps } from '../types'
import { Grid, GridCol } from '../../Grid/desktop'
import { Text } from '../../Text/desktop'

/** Параметр */
const Param: FC<IParamProps> = (props) => {
  const {
    entity,
    clarification,
    color = 'gray40',
    spacing = 'usmall',
    fontSize = 'medium',
    fontWeight = 'semibold',
  } = props

  return (
    <Grid spacing={spacing}>
      <GridCol width='auto'>
        <Text fontWeight={fontWeight} as='span' fontSize={fontSize}>
          {entity}
        </Text>
      </GridCol>

      <GridCol width='auto'>
        <Text color={color} as='span' fontSize={fontSize}>
          {clarification}
        </Text>
      </GridCol>
    </Grid>
  )
}

export { Param }
export default memo(Param)
