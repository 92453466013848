import { useCallback, useMemo, useRef } from 'react'
import { getCountForm } from '@ucheba/utils/helpers/number'
import { IUseSpecialtyRubricCardCore } from './types'

export const useSpecialtyRubricCardCore: IUseSpecialtyRubricCardCore = ({
  specialitiesCount,
  institutionsCount,
  id,
  onClick,
  color,
  isInstitutions,
  professionsCount,
  slug,
}) => {
  const specialities = useMemo(() => {
    if (typeof specialitiesCount === 'number') {
      return {
        entity: specialitiesCount,
        clarification: getCountForm(specialitiesCount, [
          'специальность',
          'специальности',
          'специальностей',
        ]),
      }
    }

    return null
  }, [specialitiesCount])

  const institutions = useMemo(() => {
    if (typeof institutionsCount === 'number') {
      return {
        entity: institutionsCount,
        clarification: getCountForm(
          institutionsCount,
          isInstitutions ? ['вуз', 'вуза', 'вузов'] : ['колледж', 'колледжа', 'колледжей']
        ),
      }
    }

    return null
  }, [institutionsCount, isInstitutions])

  const professions = useMemo(() => {
    if (typeof professionsCount === 'number') {
      return {
        entity: professionsCount,
        clarification: getCountForm(professionsCount, [
          'профессия',
          'профессии',
          'профессий',
        ]),
      }
    }

    return null
  }, [professionsCount])

  const isRender = useRef(false)

  const onClickCard = useCallback(() => {
    if (isRender.current && onClick) {
      onClick(id, slug)
    }
    isRender.current = true
  }, [id, onClick, slug])

  const cardColor = useMemo(() => color || 'silver', [color])

  return {
    specialities,
    institutions,
    onClickCard,
    cardColor,
    professions,
  }
}
