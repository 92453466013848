import { sliceName } from './constants'
import { IUserActivityState } from './types'
import { getStateError, getStateItem } from '../../../utils/core'

export default {
  loading: getStateItem<
    IUserActivityState,
    IUserActivityState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  error: getStateError<IUserActivityState>(sliceName),
}
