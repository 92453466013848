import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { userActivityThunks } from '@ucheba/store/crm/user/activity'
import { getVisitInfo } from '@ucheba/utils/hooks/useVisitsTracker'
import { UCHEBA_SITE_NAME } from '@ucheba/utils/constants/core'
import { EActivityInfoPart } from '@ucheba/utils/types/userActivityTypes'
import { authSelectors } from '@ucheba/store/auth'

interface IUseUserActivityProps {
  activityType: string
  institutionId?: number | null
  programId?: number | null
  data: {
    infoPart?: EActivityInfoPart | null
    [key: string]: any
  }
}

interface IUseUserActivity {
  (): {
    setUserActivity: (props: IUseUserActivityProps) => Promise<any>
  }
}

/* Хук для добавления пользовательских активностей */
export const useUserActivity: IUseUserActivity = () => {
  const dispatch = useDispatch()
  const isAuth = useSelector(authSelectors.isAuth)

  const setUserActivity = useCallback(
    async (props: IUseUserActivityProps) => {
      const { activityType, institutionId, data, programId } = props

      const { sourceId } = data

      delete data.sourceId

      const params: Record<string, unknown> = {
        activityType,
        data,
      }

      if (institutionId) {
        params.institutionId = Number(institutionId)
      }

      if (programId) {
        params.programId = Number(programId)
      }

      if (sourceId) {
        params.sourceId = sourceId
      }

      params.visit = getVisitInfo(UCHEBA_SITE_NAME)

      if (isAuth) {
        await dispatch(
          userActivityThunks.add({
            data: params,
          })
        )
      }
    },
    [dispatch, isAuth]
  )

  return {
    setUserActivity,
  }
}
