import styled, { StyledProps } from 'styled-components'
import { EModColor, IModColor } from '@ucheba/utils/helpers/styles/mod.types'
import { sm } from '@ucheba/utils/helpers/styles/mixins'
import { sv } from '@ucheba/utils/helpers/styles/variables'

enum EJustifyContent {
  center = 'center',
  start = 'start',
  end = 'end',
  'flex-start' = 'flex-start',
  'flex-end' = 'flex-end',
  left = 'left',
  right = 'right',
  'space-between' = 'space-between',
  'space-around' = 'space-around',
  'space-evenly' = 'space-evenly',
  stretch = 'stretch',
  inherit = 'inherit',
  normal = 'normal',
}

interface IProps extends IModColor {
  width?: string
  justifyContent: keyof typeof EJustifyContent
  alignItems?: string
}

const Block = styled.div<StyledProps<IProps>>`
  border-radius: 50%;
  display: flex;
  width: ${(props) => props.width};
  height: ${(props) => props.width};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};

  ${Object.keys(EModColor).map(
    (item) => sm.mod(EModColor[item])`
      background-color: ${sv.color[item]};
    `
  )};
`

export default { Block }
