import { IModColorBgText } from '@ucheba/utils/helpers/styles/mod.types'
import { tagsStatsSlice } from './index'

export interface ITagsStatsState {
  [key: string]: ReturnType<typeof tagsStatsSlice.reducer>
}

export interface ITagsStats {
  tags: IItemOfTags[]
  totalSpecialitiesCount: number
  totalInstitutionsCount: number
}

export interface IItemOfTags {
  id: number
  name: string
  nameDative: string
  nameGenitive: string
  nameAdjective: string
  nameProfPlural: string
  color: IModColorBgText['color']
  iconUrl: string | null
  specialitiesCount?: number | null
  institutionsCount?: number | null
  slug: string | null
}

export enum ETagsStatsDataKeys {
  parentId = 'parentId',
  programTypes = 'programTypes',
  locationId = 'locationId',
}
