import { FC, memo } from 'react'
import { Box } from '@ucheba/ui/components/Box/desktop'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/desktop'
import Image from 'next/image'
import { Circle } from '@ucheba/ui/components/Circle/desktop'
import { Heading } from '@ucheba/ui/components/Heading/desktop'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { Param } from '@ucheba/ui/components/Param/desktop'
import { EColorBgTextTransparent } from '@ucheba/utils/helpers/styles/variables'
import { getApiImageUrl } from '@ucheba/utils/helpers/string'
import styled from 'styled-components'
import { sm } from '@ucheba/utils/helpers/styles/mixins'
import { ConditionalWrapper } from '@ucheba/utils/helpers/components'
import Link from 'next/link'
import { ISpecialityRubricCardProps } from '../types'
import SC from '../base/SpecialityRubricCard.styles'
import { useSpecialtyRubricCardCore } from '../bll'

const { Block } = SC

const Info = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`

const GridSC = styled(Grid)`
  height: 100%;
`

const GridColSC = styled(GridCol)`
  ${sm.breakpoint.lgAndDown`
    display: none;
  `}

  ${sm.breakpoint.mdAndDown`
    display: block;
  `}
`

/** Карточка рубрики */
const SpecialityRubricCard: FC<ISpecialityRubricCardProps> = (props) => {
  const {
    id,
    onClick,
    name,
    specialitiesCount,
    institutionsCount,
    color = 'gold',
    iconUrl,
    height = '132px',
    isInstitutions = true,
    professionsCount,
    slug,
    getHref,
  } = props

  const {
    specialities,
    institutions,
    professions,
    onClickCard,
    cardColor = 'silver',
  } = useSpecialtyRubricCardCore({
    specialitiesCount,
    institutionsCount,
    onClick,
    id,
    color,
    isInstitutions,
    professionsCount,
    slug,
  })

  const StyledBlock = styled(Block)`
    height: ${height};
  `

  return (
    <ConditionalWrapper
      condition={!!getHref}
      wrapper={(child) => <Link href={getHref ? getHref(slug, id) : ''}>{child}</Link>}
    >
      <StyledBlock onClick={onClickCard}>
        <Box color={cardColor} spacing='small' height='100%'>
          <GridSC nowrap spacing='xxsmall' justifyContent='space-between'>
            <GridCol width='auto' shrink='initial'>
              <Info>
                <Heading h4={name} spacing='usmall' firstLetterUppercase />

                <Text html={false} fontSize='small1' lineHeight='small1'>
                  {specialities && (
                    <Param
                      entity={specialities.entity}
                      clarification={specialities.clarification}
                      color={EColorBgTextTransparent?.[cardColor]}
                    />
                  )}

                  {institutions && (
                    <Param
                      entity={institutions.entity}
                      clarification={institutions.clarification}
                      color={EColorBgTextTransparent?.[color]}
                    />
                  )}

                  {professions && (
                    <Param
                      entity={professions.entity}
                      clarification={professions.clarification}
                      color={EColorBgTextTransparent?.[color]}
                    />
                  )}
                </Text>
              </Info>
            </GridCol>

            <GridColSC width='56px'>
              <Circle width='48px'>
                {iconUrl && (
                  <Image src={getApiImageUrl(iconUrl)} width={28} height={28} alt='' />
                )}
              </Circle>
            </GridColSC>
          </GridSC>
        </Box>
      </StyledBlock>
    </ConditionalWrapper>
  )
}

export { SpecialityRubricCard }
export default memo(SpecialityRubricCard)
