import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { locationSelectors } from '@ucheba/store/location'
import { IDialog } from '@ucheba/ui/components/Dialog/types'
import { tagsStatsSelectors, tagsStatsThunks } from '@ucheba/store/tags/stats'
import {
  getFullRouterPath,
  getUpdatedPathByRegionDomain,
  objectToQueryString,
  stringifyUrl,
} from '@ucheba/utils/helpers/router'
import { EProgramTypes } from '@ucheba/utils/types/program'
import { ELoading } from '@ucheba/store/utils/response/types'
import { ETagsStatsDataKeys, IItemOfTags } from '@ucheba/store/tags/stats/types'
import { InstitutionsRankingsSubRating } from '@ucheba/store/institutions/rankings/types'
import { institutionsRankingsStatsSelectors } from '@ucheba/store/institutions/rankings/stats'
import { ESpecialitiesDataKeys } from '@ucheba/store/specialities/search/types'
import { institutionsEventsSelectors } from '@ucheba/store/institutions/events'
import { IInstitutionsEventsItem } from '@ucheba/store/institutions/events/types'
import { authSelectors } from '@ucheba/store/auth'
import { userDataSelectors } from '@ucheba/store/userData'
import { eventsSearchSelectors, eventsSearchThunks } from '@ucheba/store/events/search'
import dayjs from '@ucheba/utils/helpers/date/dayjs'
import {
  IInstitutionType,
  IIndexPageProps,
  IUseBannerContent,
  IUseIndexPageCore,
  IUseIndexPageSpecialtiesCore,
  IUseInstitutionsRankingsCore,
  TInstitutionTypeValue,
} from './types'
import isNotSubscribedImage from './assets/img__banner_is-not-subscribed.png'
import isSubscribedImage from './assets/img__banner_is-subscribed.png'
import {
  sectionsNavigationItems,
  specialitiesPath,
} from '../for-abiturients/speciality/index/constants'
import { rankingHash, rankingQuery } from '../../components/InstitutionsRanking/constants'
import { chooseInstitutionTypeButtons } from './constants'
import { IUseEventsPageHeaderCore } from '../for-abiturients/events/index/types'
import { sectionsEventsNavigationItems } from '../for-abiturients/events/index/constants'

export const useIndexPageCore: IUseIndexPageCore = () => {
  const locations = useSelector(locationSelectors.locations)
  const selectedLocations = useSelector(locationSelectors.selectedLocations)
  const [institutionType, setInstitutionType] = useState<IInstitutionType>(
    chooseInstitutionTypeButtons[0]
  )

  return {
    locations,
    selectedLocations,
    institutionType,
    setInstitutionType,
  }
}

/** Отображение специальностей по категориям (вузы или колледжи) */
export const useSpecialtiesIndexPageCore: IUseIndexPageSpecialtiesCore = () => {
  const [index, setIndex] = useState(0)
  const itemsFromStore = useSelector(tagsStatsSelectors.parents.selectAll)
  const [items, setItems] = useState<IItemOfTags[]>([])
  const [activeCategory, setActiveCategory] = useState(
    sectionsNavigationItems[index].slug
  )
  const dispatch = useDispatch()
  const loading = useSelector(tagsStatsSelectors.loading.parents)

  useEffect(() => {
    if (loading === ELoading.fulfilled) {
      setItems(itemsFromStore)
      setActiveCategory(sectionsNavigationItems[index].slug)
    }
  }, [itemsFromStore, loading]) // index не ставить

  const onClick = useCallback((_, slug) => {
    setIndex(sectionsNavigationItems.findIndex((item) => item.slug === slug))
  }, [])

  const activeIndex = useMemo(() => Number(index), [index])

  useEffect(() => {
    dispatch(
      tagsStatsThunks.fetchParents({
        data: { programTypes: [Number(sectionsNavigationItems[index].slug)] },
      })
    )
  }, [dispatch, index])

  const getSpecialitiesHref = useCallback(
    (id) => {
      return stringifyUrl(specialitiesPath, {
        set: {
          [ESpecialitiesDataKeys.topProgramTypes]:
            Number(activeCategory) === EProgramTypes.higher
              ? []
              : [EProgramTypes.vocational],
          [ETagsStatsDataKeys.parentId]: id,
        },
      })
    },
    [activeCategory]
  )

  return {
    activeIndex,
    onClick,
    activeCategory,
    items,
    getSpecialitiesHref,
  }
}

export const useBannerContent: IUseBannerContent = (props) => {
  const { isSubscribed, isNotSubscribed } = props

  /** Пока временно выставил статус авторизации
   * Если ничего не путаю, то вопрос в получении статуса подписок пользователя
   * В данный момент они с бэка не приходят
   * */
  const subscribe = useSelector(authSelectors.isAuth)

  const boxColor = useMemo(() => {
    if (subscribe) return 'gold'

    return 'lime'
  }, [subscribe])

  const buttonColor = useMemo(() => {
    if (subscribe) return 'orange'

    return 'blue'
  }, [subscribe])

  const content = useMemo(() => {
    if (subscribe) return isSubscribed

    return isNotSubscribed
  }, [isNotSubscribed, isSubscribed, subscribe])

  const imgUrl = useMemo(() => {
    if (subscribe) return isSubscribedImage

    return isNotSubscribedImage
  }, [subscribe])

  return {
    boxColor,
    content,
    buttonColor,
    imgUrl,
  }
}

/** Хендлер сабмита формы выбора типа учебного заведения */
export const useOnChooseInstitutionTypeHandler = (props: {
  closeDialog: IDialog['closeDialog']
  setInstitutionType: Dispatch<SetStateAction<IInstitutionType>>
}): ((value) => void) => {
  const { closeDialog, setInstitutionType } = props

  return useCallback(
    (value) => {
      setInstitutionType(value)

      closeDialog()
    },
    [closeDialog, setInstitutionType]
  )
}

export const useOnChooseInstitutionTypeBottomSheetHandler = (props: {
  closeDialog: Dispatch<SetStateAction<boolean>>
  setInstitutionType: Dispatch<
    SetStateAction<IIndexPageProps['content']['hero']['institutionType']>
  >
}): ((value) => void) => {
  const { closeDialog, setInstitutionType } = props

  return useCallback(
    (value) => {
      setInstitutionType(value)

      closeDialog(false)
    },
    [closeDialog, setInstitutionType]
  )
}

export const useGetInstitutionsHref = (
  institutionTypeValue?: TInstitutionTypeValue
): string => {
  const selectedLocations = useSelector(locationSelectors.selectedLocations)

  const objectToQuery = {
    'eq[]': selectedLocations.map((location) => `__l:${location.id}`),
  }

  return useMemo(() => {
    return getFullRouterPath(
      `/for-abiturients/${institutionTypeValue}`,
      objectToQueryString(objectToQuery)
    )
  }, [institutionTypeValue, objectToQuery])
}

export const useCalendarEventsList = (): IInstitutionsEventsItem[] => {
  const events = useSelector(institutionsEventsSelectors.selectAll)

  return useMemo(
    () =>
      events.map((event) => ({
        ...event,
        dateStart: event?.dateStart && dayjs(event?.dateStart).format('DD MMMM'),
      })),
    [events]
  )
}

export const useInstitutionsRankingsCore: IUseInstitutionsRankingsCore = () => {
  const rankings = useSelector(institutionsRankingsStatsSelectors.selectAll)

  const [activeDropdownMenu, setActiveDropdownMenu] = useState<null | number>(null)

  const openDropdownMenu = (id: number) => (): void => {
    setActiveDropdownMenu(id)
  }

  const isOpenDropdownMenu = (id: number): boolean => activeDropdownMenu === id

  /** Ограничение по количеству выводимых рейтингов */
  const ratingsLimit = 4
  /** Ограничение по количеству выводимых саб-рейтингов */
  const subRatingsLimit = 2

  const visibleRankingsList = useMemo(() => {
    const visibleRankings = rankings.slice(0, ratingsLimit)

    const visibleSubRatings = (
      subRatings: InstitutionsRankingsSubRating[]
    ): InstitutionsRankingsSubRating[] => subRatings.slice(0, subRatingsLimit)

    return visibleRankings.map((ranking) => ({
      ...ranking,
      children: {
        visibleSubRatings:
          ranking?.children?.length > 2
            ? visibleSubRatings(ranking.children)
            : ranking.children,
        dropdownSubRatings: ranking.children.length > 2 ? ranking.children : null,
      },
    }))
  }, [rankings])

  const getRankingHref = useCallback((rankingItemId: number) => {
    return stringifyUrl(
      '/for-abiturients/vuz/rankings',
      {},
      {},
      `${rankingHash}-${rankingItemId}`
    )
  }, [])

  const getSubRankingHref = useCallback((subRankingId: number, rankingItemId: number) => {
    return stringifyUrl(
      '/for-abiturients/vuz/rankings',
      {
        set: {
          [rankingQuery.subRanking]: subRankingId,
        },
      },
      {},
      `${rankingHash}-${rankingItemId}`
    )
  }, [])

  return {
    openDropdownMenu,
    isOpenDropdownMenu,
    visibleRankingsList,
    getRankingHref,
    getSubRankingHref,
  }
}

export const useChooseInstitutionsHref = (
  institutionTypeValue: TInstitutionTypeValue
): string => {
  const userData = useSelector(userDataSelectors.entity)
  const [href, setHref] = useState('')

  const value = institutionTypeValue === 'college' ? '1001' : '1002'

  const hrefTo = `my/profile/patch?programTypes[]=${value}&locationIds[]=${
    userData?.selectedLocations[0]?.id
  }&redirect=${encodeURIComponent(
    `/for-abiturients/planner?programType=${value}&step=interests&start=true`
  )}`

  useMemo(async () => {
    const str = await getUpdatedPathByRegionDomain({
      locationId: userData?.selectedLocations[0]?.id,
      hrefTo,
    })

    setHref(str.replace('new', ''))
  }, [hrefTo, userData?.selectedLocations])

  return href
}

export const useIndexPageEventsHeaderCore: IUseEventsPageHeaderCore = () => {
  const title = useMemo(() => 'Дни открытых дверей', [])
  const dispatch = useDispatch()
  const eventsFromStore = useSelector(eventsSearchSelectors.entity)?.items
  const loading = useSelector(eventsSearchSelectors.loading)
  const [events, setEvents] = useState<any>([])
  const [index, setIndex] = useState(0)
  const [firstLoad, setFirstLoad] = useState(true)

  const all = sectionsEventsNavigationItems
    .filter((_, key) => key)
    .map((el) => Number(el.slug))

  useEffect(() => {
    if (loading === ELoading.fulfilled) {
      setEvents(eventsFromStore)
    }
  }, [events, loading]) // index не ставить

  const onClick = useCallback((_, slug) => {
    setIndex(
      sectionsEventsNavigationItems.findIndex((item) => {
        return item.slug === slug
      })
    )
  }, [])

  // получаем активный индекс
  const activeIndex = useMemo(() => Number(index), [index])

  // делаем запрос к апи на получение мероприятий
  useEffect(() => {
    if (!firstLoad) {
      dispatch(
        eventsSearchThunks.fetch({
          data: {
            limit: 5,
            isFinished: 0,
            programTypes: !index
              ? all
              : Number(sectionsEventsNavigationItems[index].slug),
          },
        })
      )
    }

    setFirstLoad(false)
  }, [dispatch, index, firstLoad])

  return {
    title,
    activeIndex,
    onClick,
    events,
  }
}
