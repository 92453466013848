import React, { FC, memo } from 'react'
import SC from '../base/Circle.styles'
import { ICircleProps } from '../types'

const { Block } = SC

/** Круг */
const Circle: FC<ICircleProps> = (props) => {
  const {
    color = 'white',
    width,
    justifyContent = 'center',
    alignItems = 'center',
    children,
    ...otherProps
  } = props

  return (
    <Block
      color={color}
      width={width}
      justifyContent={justifyContent}
      alignItems={alignItems}
      {...otherProps}
    >
      {children}
    </Block>
  )
}

export { Circle }
export default memo(Circle)
