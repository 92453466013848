import styled, { StyledProps } from 'styled-components'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import { EModOpen, IModOpen } from '@ucheba/utils/helpers/styles/mod.types'
import { sm } from '@ucheba/utils/helpers/styles/mixins'
import { EModDropdownColor } from '@ucheba/ui/components/Dropdown/types'
import { TColor } from '@ucheba/utils/helpers/styles/types'

const Block = styled.div`
  background-color: ${sv.color.navy};
`

const Icon = styled.span<StyledProps<IModOpen>>`
  margin-left: 6px;
  transition: ${sv.duration.fast};
  display: inline-block;

  ${sm.mod(EModOpen.true)`
    transform: rotate(180deg);
  `};

  ${Object.keys(EModDropdownColor).map(
    (item) => sm.mod(EModDropdownColor[item])`
      ${sm.arrow.down(8, item as TColor)};
    `
  )};
`

const Link = styled.span`
  display: block;
`

const LinkContent = styled.span`
  cursor: pointer;
  margin-left: ${sv.spacing.xxsmall};
`

const AbsoluteText = styled.div`
  position: absolute;
`

const InstitutionText = styled(AbsoluteText)``

const StudentsText = styled(AbsoluteText)``

const ProgramsText = styled(AbsoluteText)``

export default {
  Block,
  Icon,
  Link,
  LinkContent,
  StudentsText,
  ProgramsText,
  InstitutionText,
}
