export enum EActivityTypes {
  infoPartViewed = 'info_part_viewed',
  learning_request = 'learning_request',
  lead_form_request = 'lead_form_request',
  viewing_institution_info = 'viewing_institution_info',
}

export enum EActivityInfoPart {
  egeVariants = 'egeVariants',
  benefits = 'benefits',
  news = 'news',
  opinions = 'opinions',
  questions = 'questions',
  teachers = 'teachers',
  olympiads = 'olympiads',
  employers = 'employers',
  events = 'events',
  indicators = 'indicators',
  disciplineHours = 'discipline_hours',
  lead_form = 'lead_form',
}
