import { FC } from 'react'
import { IIconProps } from './types'

/** Компонент для загрузки контентных изображений в разных форматах */
export const Picture: FC<IIconProps> = (props) => {
  const { alt, className, fallbackUrl, webpUrl, maxWidth, maxHeight, ...otherProps } =
    props

  return (
    <picture>
      <source type='image/jpeg' srcSet={fallbackUrl} />
      <source type='image/png' srcSet={fallbackUrl} />
      <source type='image/webp' srcSet={webpUrl} />

      <img
        style={{
          maxHeight,
          maxWidth,
        }}
        className={className}
        alt={alt}
        src={fallbackUrl}
        {...otherProps}
      />
    </picture>
  )
}
