import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const add = apiThunk(`${sliceName}/add`, async (params) => {
  const response = await api.post(`crm/user/activity`, params?.data, params)

  return response.data
})

export default { add }
