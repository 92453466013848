import styled from 'styled-components'

const Block = styled.div``

const StyledButton = styled.div`
  height: auto;
  padding: 0;

  & span {
    text-align: left;
  }
`

export default { Block, StyledButton }
